import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Link, Box, ErrorIcon, Typography } from '../components/UIComponents';
import DefaultButton from '../components/buttons/defaultButton';

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		height: '80vh',
		justifyContent: 'center'
	},
	spacing: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4)
	},
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '50%'
	},
	avatar: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		backgroundColor: theme.palette.secondary.main
	}
}));

function Page404() {
	const classes = useStyles();

	const goToInit = async () => {
		navigate('/app/login');
	};

	return (
		<div>
			<div className={classes.paper}>
				<div className={classes.wrapper}>
					<Avatar className={classes.avatar}>
						<ErrorIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Page not found!
					</Typography>
					<DefaultButton title="Go to initial route" buttonAction={goToInit} />
				</div>
			</div>
			<Box mt={5}>
				<Copyright />
			</Box>
		</div>
	);
}

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https://material-ui.com/">
				Oneway E-Commerce
			</Link>
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

export default Page404;

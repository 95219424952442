import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'center'
	},
	wrapper: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		position: 'relative'
	},
	wrapperSingle: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		position: 'relative'
	},
	buttonProgress: {
		color: 'white',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
}));

function DefaultButton(props) {
	const { title, disabled, loading, buttonAction, startIcon, hideWrapper, color } = props;
	const classes = useStyles();

	return (
		<div className={hideWrapper ? null : classes.root}>
			<div className={hideWrapper ? classes.wrapperSingle : classes.wrapper}>
				<Button
					size="small"
					variant="contained"
					color={color ? color : 'primary'}
					disabled={disabled}
					startIcon={startIcon}
					onClick={buttonAction}
				>
					{title}
				</Button>
				{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
			</div>
		</div>
	);
}

export default DefaultButton;
